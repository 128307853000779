import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Footer() {
    return (
      <footer className="bg-gray-200 text-black text-center p-4 -mt-4 overflow-hidden">
        <div className="container mx-auto">
          <div className="flex justify-center space-x-4">
            {/* <p href="/about" className="hover:text-gray-300">Acerca de</p> */}
            <i class="fa-solid fa-phone"></i> <p className="text-gray-800 font-semibold">3108950969</p>
            <i class="fa-solid fa-envelope"></i><p className="font-semibold">info@ipaid.com.co</p>
          </div>
          <p className="text-gray-800 text-sm mt-4">
            © {new Date().getFullYear()} Ipaid. Todos los derechos reservados.
          </p>
        </div>
      </footer>
    );
  }
export default Footer;
