import React, { useState } from "react";
import "../assets/css/DataPicker.css"; // Cambia a la ubicación correcta de tu archivo CSS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faCheck, faEraser } from "@fortawesome/free-solid-svg-icons";
import FilterButton from "../component/transacciones_c/FilterButton";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const DatePickers = ({ filterTblTransaccion, update, download }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [reference, setReference] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  // control de formulario filtro
  const [filter, setFilter] = useState(false);
  const [key, setKey] = useState(0);
  // const handleStartDateChange = (e) => {
  //   setStartDate(e.target.value);
  //   if (endDate && e.target.value > endDate) {
  //     setEndDate("");
  //   }
  // };

  // const handleEndDateChange = (e) => {
  //   if (!startDate || e.target.value >= startDate) {
  //     setEndDate(e.target.value);
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    const value = e.target.value;
    let idcliente = localStorage.getItem("Empresa");
    let config = localStorage.getItem("config");
    if (endDate && !startDate) {
      // alert("Por favor, introduce una fecha de inicio cuando especifiques una fecha de fin.");
      MySwal.fire(
        "Advertencia!",
        "introduce una fecha de inicio cuando especifiques una fecha de fin.",
        "warning"
      );
      return; // Previene el envío del formulario y finaliza la función aquí
    } else if (startDate && !endDate) {
      // alert("Por favor, introduce una fecha de fin cuando especifiques una fecha de inicio.");
      MySwal.fire(
        "Advertencia!",
        "introduce una fecha de fin cuando especifiques una fecha de inicio.",
        "warning"
      );
      return; // Previene el envío del formulario y finaliza la función aquí
    }

    const formData = {
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(paymentMethod && {
        paymentMethod:
          paymentMethod === "Bancolombia"
            ? "BANCOLOMBIA_TRANSFER"
            : paymentMethod,
      }),
      ...(reference && { reference }),
      ...(state && { state }),
      ...(email && { email }),
      idcliente: idcliente,
    };
    // Comprobar si todos los campos están vacíos, excepto idcliente
    const isFormEmpty =
      !startDate &&
      !endDate &&
      !paymentMethod &&
      !reference &&
      !state &&
      !email;

    if (isFormEmpty) {
      // Mostrar un mensaje de alerta o manejar la situación de formulario vacío
      MySwal.fire(
        "Advertencia!",
        "Por favor, ingresa al menos un criterio de búsqueda adicional.",
        "warning"
      );
      return; // Previene la ejecución de la función
    }
    // handleDate(startDate, endDate);
    filterTblTransaccion(formData);
  };
  const handleReset = () => {
    //settear correctamete el las fechas
    setStartDate('');
    setEndDate('');
    setPaymentMethod('');
    setReference('');
    setState('');
    setEmail('');
    setKey(prevKey => prevKey + 1); // Incrementar la clave para rerender
 
  };
  return (
    <form key={key} className="p-4 space-y-4 -mt-2 -mb-4" onSubmit={handleSubmit}>
      {/* Primera fila: Entradas de fecha y botón de Actualizar */}
      <div className="flex justify-between items-center ml-1">
        {/* <div className="mx-4 space-x-2 flex">
          <div>
            <label
              htmlFor="startDate"
              className="block text-sm font-medium text-gray-700"
            >
              Fecha de inicio
            </label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              className="cursor-pointer mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>
          <div>
            <label
              htmlFor="endDate"
              className="block text-sm font-medium text-gray-700"
            >
              Fecha de fin
            </label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              className="cursor-pointer mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
            />
          </div>
        </div> */}
        <div className="content-end"></div>
        <div>
          <div className="flex justify-end space-x-2 mx-5">
            {/* <button
              type="button"
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
              Filtrar
            </button> */}
            <FilterButton filter={filter} onToggle={() => setFilter(!filter)} />
            {/* <button
              type="button"
              onClick={update}
              className="bg-transparent transition-colors flex justify-center items-center"
              style={{ width: "40px", height: "40px" }}
            >
              <FontAwesomeIcon
                icon={faSync}
                className="text-black text-lg hover:rotate-180 transition-transform"
              />
            </button> */}
            <button
              type="button"
              onClick={update}
              className="bg-transparent flex justify-center items-center"
              style={{
                width: "40px",
                height: "40px",
                transition: "transform 0.5s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "rotate(360deg)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "rotate(0deg)")
              }
            >
              <FontAwesomeIcon icon={faSync} />
            </button>
            {/* <button
              type="button"
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            >
              Descargar
            </button> */}
            <div
              onClick={download}
              className="hover:text-gray-600 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#4CAF50"
                  d="M41,10H25v28h16c0.553,0,1-0.447,1-1V11C42,10.447,41.553,10,41,10z"
                ></path>
                <path
                  fill="#FFF"
                  d="M32 15H39V18H32zM32 25H39V28H32zM32 30H39V33H32zM32 20H39V23H32zM25 15H30V18H25zM25 25H30V28H25zM25 30H30V33H25zM25 20H30V23H25z"
                ></path>
                <path fill="#2E7D32" d="M27 42L6 38 6 10 27 6z"></path>
                <path
                  fill="#FFF"
                  d="M19.129,31l-2.411-4.561c-0.092-0.171-0.186-0.483-0.284-0.938h-0.037c-0.046,0.215-0.154,0.541-0.324,0.979L13.652,31H9.895l4.462-7.001L10.274,17h3.837l2.001,4.196c0.156,0.331,0.296,0.725,0.42,1.179h0.04c0.078-0.271,0.224-0.68,0.439-1.22L19.237,17h3.515l-4.199,6.939l4.316,7.059h-3.74V31z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* Segunda fila: Botones de Filtrar y Descargar */}
      <div
        className={`transition-all duration-200 ease-in-out ${
          filter ? "max-h-[1000px]" : "max-h-0"
        } overflow-hidden`}
      >
        {/* Tercera y cuarta fila: Campos de selección y entrada */}
        <div className="mx-5">
          {/* Grilla con columnas que cambian según el tamaño de la pantalla */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
            <div>
              <label
                htmlFor="startDate"
                className="block text-sm font-medium text-gray-700"
              >
                Fecha de inicio
              </label>
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="cursor-pointer mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div>
              <label
                htmlFor="endDate"
                className="block text-sm font-medium text-gray-700"
              >
                Fecha de fin
              </label>
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={endDate}
                min={startDate} // Establece la fecha mínima igual a la fecha de inicio
                onChange={(e) => setEndDate(e.target.value)}
                className="cursor-pointer mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
            </div>
            <div>
              <label
                htmlFor="paymentMethod"
                className="block text-sm font-medium text-gray-700"
              >
                Método de pago
              </label>
              <select
                id="paymentMethod"
                name="paymentMethod"
                onChange={(e) =>
                  setPaymentMethod(e.target.value === "" ? "" : e.target.value)
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              >
                <option value="">Escoge ...</option>
                <option value="PSE">PSE</option>
                <option value="BANCOLOMBIA_TRANSFER">Bancolombia</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="reference"
                className="block text-sm font-medium text-gray-700"
              >
                Referencia
              </label>
              <input
                type="text"
                id="reference"
                name="reference"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                onChange={(e) => setReference(e.target.value)}
                placeholder="Referencia de la transacción"
              />
            </div>
            <div>
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-700"
              >
                Estado
              </label>
              <select
                id="state"
                name="state"
                onChange={(e) =>
                  setState(e.target.value === "" ? "" : e.target.value)
                }
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              >
                <option value="">Escoge...</option>
                <option value="APPROVED">APPROVED</option>
                <option value="DECLINED">DECLINED</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                placeholder="Correo electrónico"
              />
            </div>
            <div className="col-span-full sm:col-span-2 lg:col-span-3 flex justify-end space-x-2">
              {/* SVG for Download icon */}

              <button
                type="submit"
                className="flex items-center justify-between px-4 py-2 border-2 border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none"
              >
                <FontAwesomeIcon icon={faCheck} className="mr-2" /> Aplicar
              </button>
              <button
                className="flex items-center justify-between px-4 py-2 border-2 border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none"
                type="button"
                onClick={handleReset}
              >
                <FontAwesomeIcon icon={faEraser} className="mr-2" /> Limpiar
              </button>
              {/* <button
                  type="submit"
                  className="bg-blue-500 text-white font-bold text-sm rounded-md hover:bg-blue-700 transition-colors px-4 py-2"
                >
                  Aplicar
                </button> */}
              {/* <button
                type="submit"
                form="myForm"
                className="bg-red-500 text-white font-bold text-sm rounded-md hover:bg-red-700 transition-colors px-4 py-2"
              >
                Cerrar
              </button> */}
            </div>
            {/* Botones alineados al final en móvil y en la misma columna en pantallas grandes */}
          </div>
        </div>
      </div>
    </form>
  );
};

export default DatePickers;
